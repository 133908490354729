var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('AConfirmation',{attrs:{"isShow":_vm.isPending},on:{"cancel":_vm.cancelSubmit,"confirm":_vm.submit}}),(_vm.api.isLoading && _vm.isFirstLoading)?_c('v-skeleton-loader',{ref:"skeleton",attrs:{"type":"table"}}):_vm._e(),_c('ASuccessWithoutReload',{attrs:{"api":this.api},on:{"close":_vm.closeSuccessModal}}),(!_vm.api.isLoading || !_vm.isFirstLoading || _vm.data!=null)?_c('v-data-table',{attrs:{"items":_vm.data,"search":_vm.search,"loading":_vm.isLoadingAllData,"headers":_vm.dataHeader},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"text-h4 pt-4",attrs:{"flat":"","height":"auto"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mt-3 mx-3"},[_c('v-row',{},[_c('v-toolbar-title',{staticClass:"font-weight-bold text-h4 my-3"},[_vm._v(" Attendance list ")])],1),_c('v-row',[_c('span',{staticClass:"mb-2",staticStyle:{"font-size":"20px"}},[_vm._v(" "+_vm._s(_vm.meeting.meeting_title)+" ")])]),_c('v-row',[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)],1)]},proxy:true},{key:"item.certificate",fn:function(ref){
var item = ref.item;
return [_c('v-col',{staticClass:"my-2"},[_c('v-row',[_c('v-btn',{style:({'width':'150px'}),attrs:{"color":"primary","loading":_vm.isLoadingCertificate,"small":""},on:{"click":function($event){return _vm.directDownload(item)}}},[_vm._v(" Download ")])],1),_c('v-row',{staticClass:"py-2"},[_c('v-btn',{style:({ 'width':'150px'}),attrs:{"color":"primary","small":"","plain":"","loading":_vm.isLoadingCertificate},on:{"click":function($event){return _vm.customizeDownload(item)}}},[_vm._v(" Customize ")])],1)],1)]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [(item.duration!==null)?_c('span',[_vm._v(" "+_vm._s((_vm.convertSecondsToMinutes(item.duration)!==0)?_vm.convertSecondsToMinutes(item.duration):"Less than 1 minutes")+" ")]):_vm._e()]}},{key:"item.index",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.data.indexOf(item)+1)+" ")])]}}],null,false,2876577429)}):_vm._e(),_c('v-dialog',{attrs:{"width":"700"},model:{value:(_vm.isCustomizeAttendance),callback:function ($$v) {_vm.isCustomizeAttendance=$$v},expression:"isCustomizeAttendance"}},[_c('ComponentGenerateCertificate',{attrs:{"meeting_id":_vm.$router.history.current.params.id,"attendance":_vm.attendance},on:{"cancel":function (){
            this$1.isCustomizeAttendance = false;
        }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }