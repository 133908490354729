<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader v-if="api.isLoading && isFirstLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <ASuccessWithoutReload  @close="closeSuccessModal" :api="this.api"/>
        <v-data-table
            v-if="!api.isLoading || !isFirstLoading || data!=null"
            :items="data"
            :search="search"
            :loading="isLoadingAllData"
            :headers="dataHeader">
            <template
                v-slot:top>
                <v-toolbar
                    flat
                    class="text-h4 pt-4"
                    height="auto">
                    <v-row
                        no-gutters>
                        <v-col
                            class="mt-3 mx-3">
                            <v-row
                                class="">
                                <v-toolbar-title
                                    class="font-weight-bold text-h4 my-3">
                                    Attendance list
                                </v-toolbar-title>
                            </v-row>
                            <v-row>
                                <span
                                    style="font-size:20px"
                                    class="mb-2">
                                    {{ meeting.meeting_title }}
                                </span>
                            </v-row>
                            <v-row>
                                <v-text-field
                                    v-model="search"
                                    dense
                                    outlined 
                                    label="Search">

                                </v-text-field>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-toolbar>
            </template>
            <template
                v-slot:item.certificate="{item}">
                <v-col
                 class="my-2">
                    <v-row>
                        <v-btn
                            @click="directDownload(item)"
                            color="primary"
                            :loading="isLoadingCertificate"
                            :style="{'width':'150px'}"
                            small>

                            Download
                        </v-btn>
                    </v-row>
                    <v-row
                        class="py-2">
                        <v-btn
                            @click="customizeDownload(item)"
                            color="primary"
                            small
                            plain
                            :loading="isLoadingCertificate"
                            :style="{ 'width':'150px'}">
                            Customize
                        </v-btn>
                    </v-row>
                </v-col>
            </template>
            <template
                v-slot:item.duration="{item}">
                <span
                    v-if="item.duration!==null">
                    {{ (convertSecondsToMinutes(item.duration)!==0)?convertSecondsToMinutes(item.duration):"Less than 1 minutes" }}
                </span>
            </template>
            <template
                v-slot:item.index="{item}">
                <span>
                    {{ data.indexOf(item)+1 }}
                </span>
            </template>
        </v-data-table>
        <v-dialog
            v-model="isCustomizeAttendance"
            width="700">
            <ComponentGenerateCertificate
            :meeting_id="$router.history.current.params.id"
            :attendance="attendance"
            @cancel="()=>{
                this.isCustomizeAttendance = false;
            }"/>
        </v-dialog>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ASuccessWithoutReload from '../../components/common/ASuccessWithoutReload.vue';
import ComponentGenerateCertificate from '../../components/certificate/ComponentGenerateCertificate.vue';
export default {
    components:{
    ASuccessWithoutReload,
    ComponentGenerateCertificate
},
    computed: mapState({
    //
}),
    props:[
    //
    ],
    data: () => ({
        pageSize:300,
        search:"",
        isLoadingAllData:true,
        buttonMinWidth:"100px",
        data:null,
        isCustomizeAttendance:false,
        attendance:null,
        isFirstLoading:true,
        isLoadingCertificate:false,
        meeting:null,
        dataHeader:[
            {
                text:'#',
                value:'index',
            },
            {
                text:'User id',
                value:'user_id',
            },
            {
                text:'Name',
                value:'name',
            },
            {
                text:'Email',
                value:'user_email'
            },
            {
                text:'Duration ( minutes )',
                value:'duration',
            },
            {
                text:'Certificate',
                value:'certificate'
            }
        ],
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="getWebinarAttendance") {
                this.meeting = resp.meeting;
                // let tempData = (this.data==null)?resp.data:{...resp.data,...this.data};
                // this.data = [...this.data,...resp.data];
                if(this.data==null) {
                    this.data = resp.data;
                }
                if(typeof(this.data) =='object') {
                    let tempData = this.$_.clone(this.data);
                    let combineData = [...tempData,...resp.data];
                    let uniqueData = this.uniqueByProperty(combineData,'user_id');
                    this.data = uniqueData;
                }
                this.isFirstLoading = false;
                if(resp.next_page_token!="") {
                    this.$api.fetch(this.fetchWebinarParticipantsList(resp.next_page_token));
                }
                else{
                    this.isLoadingAllData = false;
                }
            }

            if(resp.class==="pdfDownloadWebinar") {
                let link = document.createElement("a");
                link.href = resp.data;
                link.target = "_blank";
                document.body.appendChild(link);
                this.isLoadingCertificate = false;
                link.click();
                document.body.removeChild(link);
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let fetchWebinarParticipantsListApi = this.fetchWebinarParticipantsList(null);
            this.$api.fetch(fetchWebinarParticipantsListApi);
        },
       
        // BOC : Create a new function to build the api and return it to the fetch function
        fetchWebinarParticipantsList(pageToken) {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/meetings/webinars/participants/"+this.$router.history.current.params.id+"?page_size="+this.pageSize+"&pageToken="+pageToken ;
            return tempApi;
        }, 
        // EOC
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        closeSuccessModal() {
            this.api.isSuccesful = false;
        },
        directDownload(data) {
            this.isLoadingCertificate = true;
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/meetings/webinars/certificate/download/"+this.$router.history.current.params.id+"?email="+data.user_email+"&name="+data.name+"&duration="+this.convertSecondsToMinutes(data.duration)+"&download_type=direct_download";
            this.$api.fetch(tempApi);
        },
        customizeDownload(data) {
            this.attendance = this.$_.clone(data);
            this.attendance.duration = this.convertSecondsToMinutes(this.attendance.duration);
            this.isCustomizeAttendance = true;
            // this.isLoadingCertificate = true;
            // let tempApi = this.$_.clone(this.api);
            // tempApi.method = "GET";
            // tempApi.url = process.env.VUE_APP_SERVER_API+"/meetings/webinars/certificate/download/"+this.$router.history.current.params.id+"?email="+data.user_email+"&name="+data.name+"&duration="+this.convertSecondsToMinutes(data.duration)+"&download_type=customize";
            // this.$api.fetch(tempApi);
        },
        convertSecondsToMinutes(seconds) {
            let minutes = Math.floor(seconds/60);
            return minutes;
        },
        uniqueByProperty(arr,property) {
            return arr.filter((obj,index,array)=> {
                return array.findIndex(item => item[property] === obj[property]) === index;
        });
        }


    }
}
</script>